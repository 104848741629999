import { useEffect, useState } from "react";
import "./App.css";

function App() {
  const baseUrl = process.env.REACT_APP_REDIRECT_BASE_URL as string;
  const currentPathName = window.location.pathname + window.location.search;
  const [showError, setShowError] = useState("");
  useEffect(() => {
    if (!baseUrl) {
      setShowError("Missing environment variable: REACT_APP_REDIRECT_BASE_URL");
    } else {
      window.location.replace(baseUrl + currentPathName);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      {showError ? <h4 className="App-header">{showError}</h4> : null}
    </div>
  );
}

export default App;
